import React from "react"
import "./styles.scss"
import { Modal } from "react-bootstrap"
import PropTypes from "prop-types"

const ClarificaitonModal = ({ onHide, showModal }) => {
  return (
    <Modal
      show={showModal}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h4 className="modal-title">
          ETE DENEY EĞİTİM VE DEĞERLENDİRME TEKNOLOJİLERİ ANONİM ŞİRKETİ 6698
          SAYILI KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ KANUNU KAPSAMINDA
          İNTERNET SİTESİ AYDINLATMA METNİ
        </h4>
        <p className="description">
          Ete Deney Eğitim ve Değerlendirme Teknolojileri Anonim Şirketi, Veri
          Sorumlusu sıfatıyla 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun
          10 uncu maddesi ve Aydınlatma Yükümlülüğünün Yerine Getirilmesinde
          Uyulacak Usul ve Esaslar Hakkında Tebliğ’e uygun bir biçimde, kişisel
          verilerin elde edilmesi aşamasında veri sahiplerinin
          bilgilendirilmesini sağlamak amacıyla gerekli süreçleri yürütmektedir.
        </p>
        <p className="description">
          Ete Deney Eğitim ve Değerlendirme Teknolojileri Anonim Şirketi’ ne ait
          www.etetechnology.com internet sitesi (“Site”) üzerinden toplanan tüm
          veriler dürüstlük kurallarına uygun, doğru ve gerektiğinde güncel;
          belirli, açık ve meşru amaçlar güderek; amaçla bağlantılı, sınırlı ve
          ölçülü bir biçimde işlemekte ve bu kapsamda bütün idari ve teknik
          tedbirleri almaktadır. www.etetechnology.com tarafından sunulan hizmet
          kalitesinin arttırılması ve site faaliyetlerinin daha iyi şekilde
          yürütülmesi amacıyla, kişisel bilgileri ölçümleme çalışmaları, reklam,
          tanıtım ile sair iletişim faaliyetleri amacıyla kullanabileceği ve iş
          ortaklarıyla paylaşabileceği kişisel verileri de kayıt altına
          alınabilir ve kullanılabilir.
        </p>
        <h4 className="modal-sub-title">
          1- Kişisel Verilerin Hangi Amaçla İşleneceği
        </h4>
        <p className="description">
          Kişisel verilerinizi; kurumsal sürdürülebilirlik faaliyetlerinin
          yürütülmesi, iş ortakları ve müşterileri ile olan ilişkilerin
          yönetimi, kurumsal yönetim ve iletişim faaliyetlerinin yürütülmesi,
          talep ve şikâyet yönetimi, yetkili kişi ya da kuruluşlara mevzuattan
          kaynaklı bilgi verilmesi ve takibi amacıyla işleyebilmekteyiz. Kişisel
          verilerinizi, sunduğumuz hizmetlerin iyileştirilmesi, geliştirilmesi
          ve sunulan hizmetin doğasından kaynaklanan yükümlülüklerin yerine
          getirilmesi amacıyla işlemekteyiz. ete technology®’a iş başvurunuz
          halinde iş sözleşmesinin düzenlenmesindeki hukuki çerçevede
          başvurunuzu değerlendirmek için aşağıdaki amaçlarla verilerinizi
          işliyoruz;
        </p>
        <ul>
          <li>
            <p className="description bullet">
              Adayları inceleme, istihdam edilecek yeni adayın tespit edilmesi
              ve yeni eleman istihdam edilmesi,
            </p>
          </li>
          <li>
            <p className="description bullet">
              Özgeçmişinizde yer verdiğiniz referans kişileriyle verilerin teyit
              edilmesi,
            </p>
          </li>
          <li>
            <p className="description bullet">
              Pozisyonla ne kadar örtüştüğünüzün doğrulanması için özgeçmiş
              bilgilerinizin kaydedilmesi,
            </p>
          </li>
          <li>
            <p className="description bullet">
              Kısa ya da uzun vadede ihtiyaç duymamız ihtimaline karşı sizinle
              e-posta ortamında paylaştığımız teklif mektubunun kayıt altına
              alınması.
            </p>
          </li>
        </ul>

        <h4 className="modal-sub-title">
          2- İşlenen Kişisel Verilerin Kimlere Aktarılabileceği
        </h4>
        <p className="description">
          Ete Deney Eğitim ve Değerlendirme Teknolojileri Anonim Şirketi 6698
          sayılı Kanun’un 8 inci maddesinde öngörülen düzenlemelere uygun olarak
          ve gerekli güvenlik önlemlerini alarak kişisel veri sahibinin genel ve
          özel nitelikli kişisel verilerini yetkili kişi, kurum ve kuruluşlara
          aktarmaktadır. ETE Araştırma Geliştirme Eğitim ve Danışmanlık
          Hizmetleri yurtdışına kişisel veri aktarmamaktadır.
        </p>
        <h4 className="modal-sub-title">
          3- Kişisel Veri Toplama Yöntemimiz ve Hukuki Sebepleri
        </h4>
        <p className="description">
          Kişisel verileriniz, bu metin de belirtilen amaçların yerine
          getirilebilmesi için KVKK’nın 5 (2) maddelerinde belirtilen hukuki
          sebeplere dayanarak, otomatik veya otomatik olmayan yöntemlerle sözlü,
          yazılı veya elektronik ortamda;
        </p>

        <ul>
          <li>
            <p className="description bullet">
              E-posta iletisi gönderilmesi, telefon araması ile iletişime
              geçilmesi, başvuru yapılması, internet sitesinde yer alan
              formların doldurulması ve sosyal medya platformları üzerinden
              irtibata geçilmesi,
            </p>
          </li>
          <li>
            <p className="description bullet">
              Fuar veya seminer ziyaretlerinde kartvizit paylaşımları,
            </p>
          </li>
          <li>
            <p className="description bullet">
              www.etetechnology.com web sayfasında yer alan “İletişim Formu” nun
              doldurulması,
            </p>
          </li>
          <li>
            <p className="description bullet">
              Tarafınızca form/belge/sözleşme doldurulması veya düzenlemesiyle,
              elde edilmektedir.
            </p>
          </li>
        </ul>

        <h4 className="modal-sub-title">4- Veri Sahibi Olarak Haklarınız</h4>
        <p className="description">
          Ete Deney Eğitim ve Değerlendirme Teknolojileri Anonim Şirketi’nin
          elde ettiği kişisel verilerle ilgili veri sahipleri; 6698 sayılı
          Kanun’un 11’ inci maddesine göre, kişisel veri işlenip işlenmediğini
          öğrenme, kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
          kişisel verilerin işlenme amacını ve verilerin amacına uygun
          kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt dışında
          kişisel verilerin aktarıldığı üçüncü kişileri bilme, kişisel verilerin
          eksik veya yanlış̧ işlenmiş̧ olması hâlinde düzeltilmesini isteme, 6698
          sayılı Kanunda öngörülen şartlar çerçevesinde kişisel verilerin
          silinmesini veya yok edilmesini isteme, işlenen verilerin münhasıran
          otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
          kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, kişisel
          verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğranılması
          hâlinde zararın giderilmesini talep etme haklarına sahiptir.
        </p>
        <p className="description">
          Kişisel veri sahibi tarafından usulüne uygun olarak yapılacak
          başvuruya Ete Deney Eğitim ve Değerlendirme Teknolojileri Anonim
          Şirketi talebin niteliğine göre en geç otuz gün içinde cevap
          verecektir.
        </p>
        <p className="description">Kamuoyunun bilgisine sunulur.</p>
        <p className="description">Saygılarımızla,</p>
        <p className="description">
          Ete Deney Eğitim ve Değerlendirme Teknolojileri Anonim Şirketi Zafer
          Sb Mah. Nilüfer Sok. No:32 İç Kapı No:301 Gaziemir/ İzmir
          <br />
          info@etetechnology.com
          <br />
          +90 (232) 375 65 77
        </p>
      </Modal.Body>
    </Modal>
  )
}

ClarificaitonModal.propTypes = {
  showModal: PropTypes.boolean,
  onHide: PropTypes.any,
}

ClarificaitonModal.defaultProps = {
  showModal: false,
}

export default ClarificaitonModal
